import { Link, withI18next } from "gatsby-plugin-i18next"

import Container from "react-bootstrap/Container"
import { I18n } from "react-i18next"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const NotFoundPage = () => (
  <I18n>
    {t => (
      <Layout>
        <Container>
          <SEO title="404: Not found" />
          <h1>{t("not_found")}</h1>
          <Link to="/">{t("home")}</Link>
        </Container>
      </Layout>
    )}
  </I18n>
)

export default withI18next()(NotFoundPage)

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`
